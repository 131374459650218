<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Primary text template: <fa id="template_first" class="ml-1" icon="question-circle" />
                                <b-tooltip target="template_first" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                                    <b>The following options will be replaced when displayed:</b>
                                    <ul>
                                        <li v-pre>{{.name}} - Guest name - "John Smith" </li>
                                        <li v-pre>{{.room_name}} - Name of room - "A-101" </li>
                                        <li v-pre>{{.room_location}} - Location of room - "West"</li>
                                    </ul>
                                </b-tooltip>
                            </label>
                            <b-form-input
                                type="text"
                                placeholder="Primary text template"
                                v-model="config.primary_text_template"
                            />
                        </b-form-group>
                        <b-form-group>
                            <label>Secondary text template:<fa class="ml-1" id="template_second" icon="question-circle" />
                                <b-tooltip target="template_second" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                                    <b>The following options will be replaced when displayed:</b>
                                    <ul>
                                        <li v-pre>{{.name}} - Guest name - "John Smith" </li>
                                        <li v-pre>{{.room_name}} - Name of room - "A-101" </li>
                                        <li v-pre>{{.room_location}} - Location of room - "West"</li>
                                    </ul>
                                </b-tooltip></label>
                            <b-form-textarea
                                type="text"
                                placeholder="Secondary text template"
                                v-model="config.secondary_text_template"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <hr/>

                <VariableTranslations :fields="[{name: 'Primary text template', field: 'primary_text_template', type: 0},{name: 'Secondary text template', field: 'secondary_text_template', type: 1}]" v-model="config.translations"/>

                <hr/>

                <b-button variant="success" @click="saveConfig()">
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>
    import {BButton, BCard, BCol, BFormGroup, BFormInput, BFormTextarea, BOverlay, BRow, BTooltip} from 'bootstrap-vue'
    import VariableTranslations from '@/views/Translation/VariableTranslations'

    export default {
        components: {
            VariableTranslations,
            BOverlay,
            BCard,
            BButton,
            BRow,
            BCol,
            BFormInput,
            BFormTextarea,
            BFormGroup,
            BTooltip
        },
        data() {
            return {
                dataLoaded: false,
                config: {
                    primary_text_template: '',
                    secondary_text_template: '',
                    translations: []
                }
            }
        },
        methods: {
            loadData() {

                this.dataLoaded = false
                const thisIns = this

                const loadPromise = this.$http.get('/api/management/v1/config/base/welcome_screen')
                loadPromise.then(function(response) {
                    thisIns.config = response.data.config
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveConfig() {

                this.dataLoaded = false
                const thisIns = this

                const savePromise = this.$http.put('/api/management/v1/config/base/welcome_screen', {
                    config: this.config
                })
                savePromise.then(function() {
                    thisIns.$printSuccess('Config saved')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
