<template>
    <div v-if="languages.length > 0">
        <b-overlay :show="!dataLoaded">
            <div class="d-flex justify-content-between">
                <h2>Translations</h2>
                <b-button v-if="unusedLanguages.length > 0" variant="primary" @click="addModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </div>

            <basic-table :columns="columns" :data="translations" v-slot="props">
                <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                    <b-button variant="danger" @click="removeTranslations(props.row.language_id)">
                        <feather-icon
                            icon="Trash2Icon"
                        />
                    </b-button>
                </div>
                <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    {{ getLanguageById(props.row.language_id).name}}
                </div>
                <div v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                    <img v-if="getLanguageById(props.row.language_id).icon.length > 0" :src="'/api/management/v1/image/' + getLanguageById(props.row.language_id).icon" style="max-width: 100%; max-height: 50px;" class="rounded-lg" alt="Image"/>
                </div>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>

            </basic-table>
        </b-overlay>

        <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Language:</label>
                    <v-select
                        v-model="addObject.language_id"
                        :reduce="language => language.id"
                        :filter="filterLanguages"
                        :options="unusedLanguages"
                    >
                        <template slot="option" slot-scope="language">
                            <img v-if="language.icon && language.icon.length > 0" :src="'/api/management/v1/image/' + language.icon" style="max-width: 100%; max-height: 15px;" class="rounded-sm mr-1" alt="Image"/>
                            <span>{{language.name}}</span>
                        </template>

                        <template slot="selected-option" slot-scope="language">
                            <img v-if="language.icon && language.icon.length > 0" :src="'/api/management/v1/image/' + language.icon" style="max-width: 100%; max-height: 15px;" class="rounded-sm mr-1" alt="Image"/>
                            <span>{{language.name}}</span>
                        </template>
                    </v-select>

                </b-form-group>
                <b-form-group :key="field.field" v-for="field in fields">
                    <label>{{ field.name }}:</label>
                    <b-form-input
                        v-if="field.type === 0"
                        type="text"
                        :placeholder="field.name"
                        v-model="addObject[field.field]"
                    />
                    <b-form-textarea
                        v-else-if="field.type === 1"
                        type="text"
                        :placeholder="field.name"
                        v-model="addObject[field.field]"
                    />
                </b-form-group>
            </template>
            <template #modal-footer>

                <b-button variant="danger" @click="addModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addTranslation">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BFormTextarea, BModal, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'

    export default {
        components: {
            BOverlay,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BasicTable,
            BFormTextarea,
            vSelect
        },
        props: {
            value: {
                type: Array,
                required: true,
                default: () => []
            },
            fields: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

                dataLoaded: false,
                translations: [],

                languages: [],
                columns: [],

                addModalActive: false,
                addObject: {}

            }
        },
        methods: {
            loadData() {

                this.dataLoaded = false

                const thisIns = this

                const languagesPromise = this.$http.get('/api/management/v1/language')
                languagesPromise.then(function(response) {
                    thisIns.languages = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([languagesPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addTranslation() {
                this.translations.push(this.addObject)
                this.addModalActive = false
                this.addObject = {}
            },
            removeTranslations(languageId) {
                this.translations.splice(this.translations.findIndex(translation => translation.language_id === languageId))
            },
            filterLanguages(languages, search) {
                return languages.filter(language => language.name.toLowerCase().includes(search.toLowerCase()))
            },
            getLanguageById(languageId) {
                return this.languages.find(language => language.id === languageId) || {icon: '', name: 'Unknown'}
            }
        },
        computed: {
            unusedLanguages() {
                if (!this.languages || !this.translations) return []
                const thisIns = this
                return this.languages.filter(language => !thisIns.translations.find(translation => translation.language_id === language.id) && !language.primary)
            }
        },
        watch: {
            value: {
                handler() {
                    if (JSON.stringify(this.translations) !== JSON.stringify(this.value)) {
                        this.translations = this.value
                    }
                },
                deep: true
            },
            translations: {
                handler() {
                    if (JSON.stringify(this.translations) !== JSON.stringify(this.value)) {
                        this.$emit('input', this.translations)
                    }
                },
                deep: true
            }
        },
        created() {
            this.translations = this.value
            if (this.value) {
                this.translations = this.value
            } else {
                this.translations = []
            }
            const columns = []
            columns.push(
                {
                    label: 'Icon',
                    displayType: 3,
                    field: 'language_id',
                    filterOptions: {
                        enabled: false
                    }
                },
                {
                    label: 'Language',
                    displayType: 2,
                    field: 'language_id',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Search Language'
                    }
                }
            )

            for (let x = 0; x < this.fields.length; x++) {
                columns.push(
                    {
                        label: this.fields[x].name,
                        displayType: 0,
                        field: this.fields[x].field,
                        filterOptions: {
                            enabled: true,
                            placeholder: `Search ${  this.fields[x].name}`
                        }
                    }
                )
            }

            columns.push(
                {
                    label: 'Actions',
                    displayType: 1,
                    field: 'actions',
                    filterOptions: {
                        enabled: false
                    }
                }
            )

            this.columns = columns
        },
        mounted() {
            this.loadData()
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>